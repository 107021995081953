import { createApi } from '@reduxjs/toolkit/query/react';

import type { ImageProps } from 'next/image';

import customBaseQuery from 'Services/customBaseQuery';

import { FRAGRANCE_FREE as fragranceFreeLabel } from 'constants/haircareFragrances';

import { getFragranceDetails } from 'utils/fragrances';

type FragranceCategories<T> = {
  haircare: T;
  hairoil: T;
  skincare: T;
};

type RawSubscriptionFragrance = {
  name: string;
  fragrance_group: 'global' | 'hairoil';
  recommended?: boolean;
};

type EnrichedSubscriptionFragrance = RawSubscriptionFragrance & {
  description: string;
  img: ImageProps;
  imageAlt?: string;
  label: string;
  selected: boolean;
};

type SubscriptionFragrancesOptions = FragranceCategories<Array<EnrichedSubscriptionFragrance>>;

const enrichFragrance = (
  rawFragrance: RawSubscriptionFragrance,
  selectedFragrance: string,
): EnrichedSubscriptionFragrance => {
  // @ts-expect-error - TODO: clean-up getFragranceDetails
  const { details, ingredients, url, name, urlsByAssetName, imageAlt } = getFragranceDetails(
    rawFragrance.name,
  );

  const ret = {
    ...rawFragrance,
    description: rawFragrance.name !== fragranceFreeLabel ? details ?? ingredients : null,
    img: urlsByAssetName?.darkFragFreeSignImg ?? url,
    imageAlt,
    label: name,
    name: rawFragrance.name,
    recommended: rawFragrance?.recommended ?? false,
    selected: selectedFragrance === rawFragrance.name,
  };

  return ret;
};

const deriveFragrancesOptions = (
  rawFragrances: Array<RawSubscriptionFragrance>,
  category: string,
  selectedFragrancesByCategory: FragranceCategories<string>,
) => {
  const additionalRawFragrancesByCategory = {
    haircare: [{ name: 'FRAGRANCE_FREE', fragrance_group: 'global' }],
    hairoil: [{ name: 'FRAGRANCE_FREE', fragrance_group: 'hairoil' }],
    // skincare: [] /* no need to add FRAGRANCE_FREE for skincare (sent by API) */,
  } as const;

  return {
    haircare:
      category === 'haircare'
        ? rawFragrances
            .filter((rawFragrance) => rawFragrance.fragrance_group !== 'hairoil')
            .concat(additionalRawFragrancesByCategory.haircare)
            .map((rawFragrance) =>
              enrichFragrance(rawFragrance, selectedFragrancesByCategory.haircare),
            )
        : [],
    hairoil:
      category === 'haircare'
        ? rawFragrances
            .filter((rawFragrance) => rawFragrance.fragrance_group === 'hairoil')
            .concat(additionalRawFragrancesByCategory.hairoil)
            .map((rawFragrance) =>
              enrichFragrance(rawFragrance, selectedFragrancesByCategory.hairoil),
            )
        : [],
    skincare:
      category === 'skincare'
        ? rawFragrances.map((rawFragrance) =>
            enrichFragrance(rawFragrance, selectedFragrancesByCategory.skincare),
          )
        : [],
  };
};

const subscriptionsApiSlice = createApi({
  reducerPath: 'subscriptionsApi',
  baseQuery: customBaseQuery,
  endpoints: (build) => ({
    subscriptionFragrancesQuery: build.query<
      SubscriptionFragrancesOptions,
      {
        category: 'haircare' | 'skincare' | 'supplements'; // TODO: proper fix after launch: `supplements should not be a case`
        selectedFragrancesByCategory: FragranceCategories<string>;
      }
    >({
      query: ({ category }) => {
        const categoryParam = category === 'supplements' ? 'haircare' : category; // TODO: proper fix after launch
        const allParam = category === 'haircare' ? `&all=true` : '';

        return {
          url: `v1/account/hair_profile/fragrances/?category=${categoryParam}${allParam}`,
          endpointName: 'subscriptions/subscriptionFragrancesQuery',
        };
      },
      transformResponse: (
        response: { fragrances: Array<RawSubscriptionFragrance> },
        _meta,
        { category, selectedFragrancesByCategory },
      ) => {
        const meh = deriveFragrancesOptions(
          response.fragrances,
          category,
          selectedFragrancesByCategory,
        );

        return meh;
      },
      keepUnusedDataFor: 0, // no caching for now
    }),
  }),
});

export default subscriptionsApiSlice;

export const { subscriptionFragrancesQuery } = subscriptionsApiSlice.endpoints;
